import React, {
  useCallback,
  useEffect,
  createRef,
  useRef,
  useState,
} from 'react'
import './home.css'
import { FaFacebookF } from 'react-icons/fa'
import { FiUpload } from 'react-icons/fi'
import { AiOutlineCamera, AiOutlinePrinter } from 'react-icons/ai'
import { BsInstagram, BsCheckLg } from 'react-icons/bs'
import { GiAnticlockwiseRotation } from 'react-icons/gi'

import { Images } from '../../config/images'
import downloadjs from 'downloadjs'
import html2canvas from 'html2canvas'
import DownloadIcon from '../../config/assets/download.png'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import { toPng } from 'html-to-image'

import { storage } from '../../firebase'
import {
  addDoc,
  collection,
  getDocs,
  serverTimestamp,
} from 'firebase/firestore'
import { db } from '../../firebase'
import { getDownloadURL, ref, uploadString } from 'firebase/storage'
import PhotoEditor from './PhotoEditor.js'

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })

const Home = () => {
  const componentRef = useRef()
  const [loading, setLoading] = useState(false)
  const [landscape, setLandScape] = useState('portrait')
  const editorRef = useRef(null)

  const [image, setImage] = useState(null)

  // display image in frame
  const [frame, setFrame] = useState(false)

  const [upload, setUpload] = useState(true)

  // get image from gallery
  const getimageOnChange = async (e) => {
    let file = await toBase64(e.target.files[0])

    setImage(file)

    setFrame(true)
    setUpload(false)
  }

  const handleCaptureClick = useCallback(async () => {
    setLoading(true)
    setTimeout(async () => {
      let origImage = await document.querySelector('.mainEditor')

      let canvas = await html2canvas(origImage, {
        scale: 2,
      })

      let dataURL = canvas.toDataURL('image/jpeg')

      await downloadjs(dataURL, 'download.jpeg', 'image/jpeg')
      const imageRef = ref(storage, `file/images${+new Date()}`)
      uploadString(imageRef, dataURL, 'data_url')
          .then((snapshot) => {
            console.log('Uploaded a data_url string!')
          })
          .then(() => {
            getDownloadURL(imageRef).then(async (url) => {
              console.log(url, 'url is')
              let test = await addDoc(collection(db, 'images'), {
                image: url,
                createdAt: serverTimestamp(),
              })
              if (test) {
                setLoading(false)

                window.location.reload()
                // window.location.assign('/photokiosk')
              }
            })
          })      
     
    }, 1600)
  }, [])

  // =================================get Logo ====================================//
  const [myData, setMyData] = useState([])
  useEffect(() => {
    const getCities = async () => {
      // setLoading(true)
      const data = await getDocs(collection(db, 'logoes'))
      console.log(data, 'data is')
      setMyData(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
      // setLoading(false)
    }
    getCities()
  }, [])
  console.log(myData)
  return (
    <>
      <>
        {loading && (
          <div className='loading'>
            <div className='loading-wrapper'>
              {/* <h1 className="text-white">IMPRIMIENDO...</h1> */}
            </div>
          </div>
        )}
        <div className={upload ? 'upload' : frame ? 'frame' : 'home'}>
          <div
            className={
              upload
                ? 'upload-wrappers'
                : frame
                ? 'frame-wrapper'
                : 'home-wrapper'
            }
          >
            {frame ? (
              <>
                <img src={Images.Logo} style={{ height: '115px' }} />
                <PhotoEditor
                  landscape={landscape}
                  image={image}
                  logo={myData[0]?.image}
                  editorRef={editorRef}
                />
              </>
            ) : !upload ? (
              <h1>{loading ? 'IMPRESION...' : ' BIENVENIDAS...'}</h1>
            ) : (
              <div className='upload-wrapper'>
                <img src={Images.Logo} />
                <h1 className='text-center bold text-white'>FOTO KIOSCO</h1>
                <div className='upload-image'>
                  <label class='filebutton'>
                    <FiUpload color='#000' size={40} />
                    <span>
                      <input
                        onChange={(e) => getimageOnChange(e)}
                        type='file'
                        id='myfile'
                        name='myfile'
                      />
                    </span>
                  </label>
                </div>
                <p>CARGAR FOTO</p>
              </div>
            )}
            <div className='home-bottom'>
              {frame ? (
                <div
                  className='bottom-social-icons'
                  style={{
                    width: '330px',
                    position: 'fixed',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    bottom: '5%',
                  }}
                >
                  <div
                    className='bottom-social-icons-wrapper'
                    style={{ flexDirection: 'column', cursor: 'pointer' }}
                  >
                    <div className='bottom-social-icons-wrapper-icon'>
                      <label style={{ height: 20 }}>
                        <AiOutlineCamera size={25} />
                        {/* <FiUpload color="#000" size={40} /> */}
                        <span>
                          <input
                            onChange={(e) => getimageOnChange(e)}
                            type='file'
                            id='myfile'
                            name='myfile'
                          />
                        </span>
                      </label>
                    </div>
                    <p style={{ fontSize: 10, fontWeight: '500' }}>
                      CAMBIAR FOTO
                    </p>
                  </div>
                  <div
                    className='bottom-social-icons-wrapper'
                    style={{ flexDirection: 'column', cursor: 'pointer' }}
                  >
                    <div
                      onClick={handleCaptureClick}
                      className='bottom-social-icons-wrapper-icon'
                    >
                      <img
                        src={DownloadIcon}
                        style={{ objectFit: 'contain', width: 23, height: 23 }}
                      />
                    </div>
                    <p style={{ fontSize: 10, fontWeight: '500' }}>DESCARGAR</p>
                  </div>
                  <div
                    className='bottom-social-icons-wrapper'
                    style={{ flexDirection: 'column', cursor: 'pointer' }}
                  >
                    <div
                      onClick={() =>
                        setLandScape(
                          landscape == 'portrait'
                            ? 'landscape'
                            : landscape == 'portrait'
                            ? 'landscape'
                            : 'portrait'
                        )
                      }
                      className='bottom-social-icons-wrapper-icon'
                    >
                      <GiAnticlockwiseRotation size={22} />
                    </div>
                    <p style={{ fontSize: 10, fontWeight: '500' }}>
                      GIRAR MARCO
                    </p>
                  </div>

                  <div
                    onClick={handleCaptureClick}
                    className='bottom-social-icons-wrapper'
                    style={{ flexDirection: 'column', cursor: 'pointer' }}
                  >
                    <div className='bottom-social-icons-wrapper-icon'>
                      <AiOutlinePrinter size={25} />
                    </div>
                    <p style={{ fontSize: 10, fontWeight: '500' }}>
                      {'IMPRIMIR'}
                    </p>
                  </div>
                </div>
              ) : (
                <div className='bottom-social-icons'>
                  <div className='bottom-social-icons-wrapper'>
                    <div className='bottom-social-icons-wrapper-icon'>
                      <FaFacebookF />
                    </div>
                    <b>GM EVENTS MEXICALI</b>
                  </div>
                  <div className='bottom-social-icons-wrapper'>
                    <div className='bottom-social-icons-wrapper-icon'>
                      <BsInstagram />
                    </div>
                    <b>EVENTSGM</b>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    </>
  )
}

export default Home
