// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyCrVgLsK97WZymoAnos_04g-zR2NRVqTLQ",
    authDomain: "photo-kiosk.firebaseapp.com",
    projectId: "photo-kiosk",
    storageBucket: "photo-kiosk.appspot.com",
    messagingSenderId: "373687674731",
    appId: "1:373687674731:web:1e7cd696a89ae47b200e2e",
    measurementId: "G-ZPMN9521YW"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);