import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import { doc, setDoc, addDoc, collection, getDocs } from "firebase/firestore";
import { app, db } from "../firebase";
import './login.css'
import { useEffect } from "react";

const Login = () => {
    const auth = getAuth(app);
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [mydata, setMyData] = useState([])
    console.log(mydata)

    const Signin = () => {
        setLoading(true)
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in 
                const user = userCredential.user;
                console.log(user.stsTokenManager.accessToken)
                localStorage.setItem('token', user.stsTokenManager.accessToken)
                setLoading(false)
                navigate('/admin')
                // ...
            })
            .catch((error) => {
                setLoading(false)
                const errorCode = error.code;
                alert(errorCode)
            });
    }


    useEffect(() => {
        const getCities = async () => {
            const data = await getDocs(collection(db, 'cities'))
            setMyData(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
        }
        getCities()
    }, [])
    return (
        <div className="signup">
            <form>
                <div className="form-group">
                    <h5>Admin Login   </h5>
                </div>
                <div className="form-group">
                    <label for="email">Email address:</label>
                    <input onChange={(e) => setEmail(e.target.value)} type="email" className="form-control" placeholder="Enter email" id="email" />
                </div>
                <div className="form-group">
                    <label for="pwd">Password:</label>
                    <input onChange={(e) => setPassword(e.target.value)} type="password" className="form-control" placeholder="Enter password" id="pwd" />
                </div>
                <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <button type="button" onClick={Signin} className="btn btn-primary">{loading ? 'Please wait..' : 'Login'}</button>
                <br />
                <br />
            </form>
        </div>
    )
}

export default Login