import { getAuth,createUserWithEmailAndPassword } from "firebase/auth";
import React, { useState } from 'react'
import { app } from "../firebase";
import './signup.css'

const SignUp = () => {
    const auth = getAuth(app);
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const Register = () => {
        createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in 
                const user = userCredential.user;
                console.log(user)
                alert('user created successfully')
                // ...
            })
            .catch((error) => {
                const errorCode = error.code;
                console.log(errorCode)
            });
    }
    return (
        <div className="signup">
            <form>
                <div className="form-group">
                    <h5>Admin Register account  </h5>
                </div>
                <div className="form-group">
                    <label for="email">Email address:</label>
                    <input onChange={(e) => setEmail(e.target.value)} type="email" className="form-control" placeholder="Enter email" id="email" />
                </div>
                <div className="form-group">
                    <label for="pwd">Password:</label>
                    <input onChange={(e) => setPassword(e.target.value)} type="password" className="form-control" placeholder="Enter password" id="pwd" />
                </div>
                <button type="button" onClick={Register} className="btn btn-primary">Submit</button>
            </form>
        </div>
    )
}

export default SignUp