import React, { useEffect, useState } from 'react'
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom'
import Admin from './Admin/Admin'
import ImageDetail from './Admin/ImageDetail/ImageDetail'
import AdminProtectedRoutes from './AdminRoute'
import './App.css'
import Home from './components/Home/Home'
import Photo from './components/Photo'
import Login from './Login/Login'
import SignUp from './SignUp/SignUp'

const App = () => {
  const [active, setActive] = useState(false)
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<AdminProtectedRoutes />}>
          <Route element={<Admin />} path='/admin' />
          <Route element={<ImageDetail />} path='/admin/:id' />
        </Route>
        <Route element={<Home />} path='/' />
        <Route element={<Photo />} path='/impre' />
        <Route element={<SignUp />} path='/register' />
        <Route element={<Login />} path='/login' />
      </Routes>
    </BrowserRouter>
  )
}

export default App
