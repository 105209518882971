import React, { createRef, useEffect, useState, useRef } from 'react'
import { Button, Upload } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useScreenshot } from 'use-react-screenshot'

import { Modal } from 'antd'
import {
  addDoc,
  collection,
  doc,
  getDocs,
  updateDoc,
  deleteDoc,
  getFirestore,
  where,
  query,
  writeBatch,
} from 'firebase/firestore'
import { app, db, storage } from '../../firebase'
import ReactPaginate from 'react-paginate'
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs'
import { Rnd } from 'react-rnd'
import downloadjs from 'downloadjs'
import html2canvas from 'html2canvas'
import PhotoEditor from '../Home/PhotoEditor'
const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })

const MyTable = (props) => {
  const [toggle, setToggle] = useState(true)
  const navigate = useNavigate()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isUploadImage, setIsUploadImage] = useState(false)
  const [selectedLogo, setSelectedLogo] = useState(false)
  const [selectedImage, setSelectedImage] = useState(false)
  const editorRef = useRef(null)

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
    setIsUploadImage(false)
    setSelectedLogo('')
    setSelectedImage('')
  }
  const handleOpenUpload = () => {
    var xhr = new XMLHttpRequest()
    xhr.responseType = 'blob'
    xhr.onload = (event) => {
      var blob = xhr.response
      //create a file from the returned blob
      var file = new File([blob], 'image name', { type: blob.type })
      //grab the a tag
      setSelectedImage(URL.createObjectURL(file))
    }
    xhr.open('GET', selected[0].image)
    xhr.send()

    setSelectedImage(selected[0].image)

    setIsUploadImage(true)
    setSelectedLogo('')
    setSelectedImage('')
  }

  const refDragContainer = createRef(null)
  const [myData, setMyData] = useState([])

  // screen shot
  const refshot = createRef(null)
  const [shotimage, takeScreenshot] = useScreenshot()
  const [logo, setLogo] = useState(myData[0]?.image)
  const getImage = async () => await takeScreenshot(refshot.current)

  // =================================get Logo ====================================//
  let id = myData[0]?.id
  const [loading, setLoading] = useState([])
  useEffect(() => {
    const getCities = async () => {
      setLoading(true)
      const data = await getDocs(collection(db, 'logoes'))
      setMyData(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
      setLoading(false)
    }
    getCities()
    if (logo) {
      getImage()
    }
  }, [logo, shotimage])

  const getLogoOnChange = async (e) => {
    let file = await toBase64(e.target.files[0])

    setSelectedLogo(file)
  }
  console.log('selected logo is', selectedLogo)
  const [success, setSuccess] = useState(false)
  const [msg, setMsg] = useState(false)
  console.log(id, 'id is')
  const handleOk = async () => {
    setMsg(true)
    const updateLogo = doc(db, 'logoes', id)
    setLoading(true)
    updateDoc(updateLogo, {
      image: logo,
    })
      .then((response) => {
        setSuccess(true)
        setIsModalOpen(false)
        setMsg(false)
        // window.location.assign("/admin")
      })
      .catch((error) => {
        console.log(error.message)
      })
  }

  let myLogo = myData[0]?.image

  // =========================== Multi Selection begin =================//
  const [selected, setSelected] = useState([])
  console.log('selected', selected)
  let selectedImagesForDownload = selected?.map((i) => i.image)

  const handleMultiSelection = (item) => {
    if (selected.includes(item)) {
      let temp = selected.filter((i) => i !== item)
      setSelected(temp)
    } else {
      setSelected((prev) => [...prev, item])
    }
  }

  const downloadModifiedImage = async () => {
    let origImage = await document.querySelector('.imageLogoChange > div')

    let canvas = await html2canvas(origImage)

    // await takeScreenshot(refDragContainer.current)

    let dataURL = canvas.toDataURL('image/jpeg')

    // console.log(shotimage)

    await downloadjs(dataURL, 'download.jpeg', 'image/jpeg')
  }

  const handleDeleteBulk = async () => {
    // console.log(selected.map((i) => i.id))
    // await deleteDoc(doc(db, "images", selected[0].id));
    selected.forEach((itemId) => {
      deleteDoc(doc(db, 'images', itemId.id))
      const newItems = props.data.filter((item) => !selected.includes(item))
      props.setData(newItems)
    })
  }
  // =========================== Multi Selection end =================//

  const DownloadBulk = () => {
    selectedImagesForDownload.forEach((imageUrl) => {
      fetch(imageUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'image.jpeg')
          document.body.appendChild(link)
          link.click()
        })
        .catch((error) => console.error(error))
    })
  }

  const handleSelectAll = () => {
    let temp = props.data.map((i) => {
      setSelected((prev) => [...prev, i])
    })
    console.log(temp)
  }

  // pagination
  const [pageNumber, setPageNumber] = useState(0)
  console.log('pageNumber', pageNumber)

  const usersPerPage = 18
  const pagesVisited = pageNumber * usersPerPage

  const pageCount = Math.ceil(props.data?.length / usersPerPage)

  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }
  return (
    <>
      <a id='tagID'></a>
      <>
        <Modal
          title='Update Logo'
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          className='updateLogoModal'
        >
          <div
            ref={refshot}
            className='form-group'
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img
              src={logo}
              style={{ width: 200, height: 200, objectFit: 'contain' }}
            />
          </div>

          {/* <PhotoEditor
            isAdmin={true}
            landscape={'portrait'}
            image={
              'https://firebasestorage.googleapis.com/v0/b/photo-kiosk.appspot.com/o/file%2Fimages1685438031449?alt=media&token=eb6975d4-2f21-48d2-b4fe-cbdf9b882455'
            }
            logo={''}
            editorRef={editorRef}
          /> */}
          {/* <img
            src={myData[0]?.image}
            style={{ width: 50, height: 50, objectFit: 'contain' }}
          /> */}
          <div className='form-group'>
            <input
              type={'file'}
              onChange={async (e) => {
                // setLogo(URL.createObjectURL(e.target.files[0]))
                var file = e.target.files[0]
                var reader = new FileReader()
                reader.onloadend = function () {
                  setLogo(reader.result)
                }
                reader.readAsDataURL(file)
                await getImage()
              }}
            />
          </div>
          {msg && (
            <p className='text-center alert alert-info'>Please wait...</p>
          )}
        </Modal>
        {/* ~~~~~~~~~~Upload Logo Modal~~~~~~~~~~~~~~~ */}
        <Modal
          title='Upload Logo'
          className='uploadModal'
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          open={isUploadImage}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <div
            ref={refDragContainer}
            className='form-group imageLogoChange'
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div>
              <img src={selectedImage} style={{ width: '453px' }} />

              {/* {selectedLogo ? (
                <Rnd
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: 'solid 1px #ddd',
                    background: '#f0f0f0',
                  }}
                  default={{
                    x: 0,
                    y: 0,
                    width: 320,
                    height: 200,
                  }}
                  enableResizing={true}
                >
                  <img
                    src={selectedLogo}
                    style={{ width: '100%', height: '100%' }}
                  />
                </Rnd>
              ) : (
                ''
              )} */}
            </div>
          </div>
          {/* <img
            src={myData[0]?.image}
            style={{ width: 50, height: 50, objectFit: "contain" }}
          /> */}
          {/* <div className="form-group">
            <input
              type={"file"}
              onChange={async (e) => {
                setLogo(URL.createObjectURL(e.target.files[0]));
                await getImage();
              }}
            />
          </div> */}

          {msg && (
            <p className='text-center alert alert-info'>Please wait...</p>
          )}
          <div className='d-flex'>
            <div style={{ position: 'relative', cursor: 'pointer' }}>
              <input
                style={{
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  height: '35px',
                  opacity: '0',
                }}
                onChange={(e) => getLogoOnChange(e)}
                type='file'
                id='myfile'
                name='myfile'
              />
              <button
                className='btn btn-success form-group ml-2'
                // onClick={() => DownloadBulk()}
              >
                Select Logo
              </button>
            </div>
            <button
              className='btn btn-success form-group ml-2'
              onClick={() => downloadModifiedImage()}
            >
              Download
            </button>
          </div>
        </Modal>
      </>
      <div>
        <h4>Images</h4>
        {success && (
          <h6 className='alert alert-success w-25'>
            logo Updated successfully
          </h6>
        )}
        {/* <button className='btn btn-info form-group' onClick={() => setToggle(!toggle)}>{toggle ? 'Update' : 'Preview'}</button> */}
        <button onClick={showModal} className='btn btn-info form-group ml-2'>
          Update Logo
        </button>
        {selected.length > 0 ? (
          <>
            <button
              className='btn btn-success form-group ml-2'
              onClick={() => DownloadBulk()}
            >
              Download
            </button>
            <button
              className='btn btn-danger form-group ml-2'
              onClick={() => handleDeleteBulk()}
            >
              Delete
            </button>
            <button
              className='btn btn-primary form-group ml-2'
              onClick={() => handleOpenUpload()}
            >
              Upload Logo
            </button>
            <button
              className='btn form-group ml-2'
              onClick={() => handleSelectAll()}
            >
              Select All
            </button>
          </>
        ) : (
          ''
        )}
        <div className='row'>
          {props.data
            .slice(pagesVisited, pagesVisited + usersPerPage)
            ?.map((i) => (
              <div
                className='col-lg-2 mb-2'
                onClick={() => {
                  handleMultiSelection(i)
                  // if (toggle) {
                  //   navigate(`./${i.id}`, { state: i })
                  // }
                }}
              >
                {!toggle ? (
                  <img
                    width={150}
                    height={150}
                    style={{ objectFit: 'contain', cursor: 'pointer' }}
                    src={i.image}
                  />
                ) : (
                  <img
                    width={150}
                    height={150}
                    style={{
                      objectFit: 'contain',
                      cursor: 'pointer',
                      background: selected.includes(i) && '#9ae6ae',
                    }}
                    src={i.image}
                  />
                )}
              </div>
            ))}
        </div>
        <div className='pagination-button'>
          <ReactPaginate
            previousLabel={<BsChevronLeft />}
            nextLabel={<BsChevronRight />}
            pageCount={pageCount}
            onPageChange={changePage}
            containerClassName={'paginationBttns'}
            previousLinkClassName={'previousBttn'}
            nextLinkClassName={'nextBttn'}
            disabledClassName={'paginationDisabled'}
            activeClassName={'paginationActive'}
          />
        </div>
      </div>
    </>
  )
}
// {
//   props.data.map((i) => (
//       <Image
//         width={200}
//         src={i.image}
//       />
//   ))
// }
export default MyTable
