import { Component, useState, useEffect } from 'react'
import styles from './editor.module.css'
import AvatarEditor from 'react-avatar-editor'
import Hammer from 'react-hammerjs'

const getCircularReplacer = () => {
  const seen = new WeakSet()
  return (key, value) => {
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        return
      }
      seen.add(value)
    }
    return value
  }
}

const PhotoEditor = ({ image, landscape, logo, editorRef, isAdmin }) => {
  const [sizes, setSizes] = useState({})
  const [imageScale, setImageScale] = useState(1)
  const [prevVal, setPrevVal] = useState(0)

  const onPinchOut = (e) => {
    console.log(e.scale, 'in ')
    setImageScale(imageScale + 0.1)
  }
  const onPinchIn = (e) => {
    // console.log(Object.keys(e.deltaX))
    console.log(e.scale, 'out', imageScale)
    if (imageScale > 1) setImageScale(imageScale - 0.1)
  }

  const onPinch = (e) => {
    if (e.scale > prevVal) {
      setImageScale(imageScale + 0.1)
    } else {
      if (imageScale > 1) setImageScale(imageScale - 0.1)
    }
    setPrevVal(e.scale)
  }

  useEffect(() => {
    let selector = '.containerLand'
    if (landscape == 'portrait') {
      selector = '.containerPort'
    }

    if (isAdmin) {
    }

    var tempCo = document.querySelector(selector)
    tempCo = tempCo.getBoundingClientRect()
    setSizes(tempCo)
    setImageScale(1)
  }, [landscape])

  let options = {
    direction: Hammer.DIRECTION_ALL,
    touchAction: 'compute',
    recognizers: {
      pinch: { enable: true },
    },
  }

  return (
    <div
      className={`${styles.container} mainEditor ${
        isAdmin ? 'adminView' : ''
      } ${landscape == 'portrait' ? '' : styles.rotated}`}
      ref={editorRef}
    >
      {/* <img src={image} alt='' /> */}
      {landscape == 'portrait' ? (
        <Hammer options={options} onPinch={onPinch}>
          <div
            className={`${styles.imageContainer}
           imageContainer containerPort`}
          >
            <AvatarEditor
              image={image}
              width={isAdmin ? 293 : sizes?.width}
              height={isAdmin ? 300 : sizes?.height}
              scale={imageScale}
              rotate={0}
              border={0}
            />
          </div>
        </Hammer>
      ) : (
        <Hammer options={options} onPinch={onPinch}>
          <div
            className={`${styles.imageContainer} ${styles.imageRotated} imageContainer containerLand`}
          >
            <AvatarEditor
              image={image}
              width={sizes?.width}
              height={sizes?.height}
              scale={imageScale}
              rotate={0}
              border={0}
            />
          </div>
        </Hammer>
      )}
      <div className={`${styles.logoContainer} logoContainerImage`}>
        <img
          className='img-responsive'
          src={logo}
          style={{
            objectFit: 'cover',
            maxHeight: '60px',
            minHeight: '60px',
            maxWidth: '300px',
          }}
        />
      </div>
    </div>
  )
}

export default PhotoEditor
